<template>
  <section class="calculator">
    <v-form
      ref="form"
      v-model="isValid"
      class="calculator__form form"
      @submit.prevent="submit"
    >
      <v-layout class="form__sender-address">
        <v-text-field
          ref="senderInput"
          v-model="data.sender_address.value"
          label="Адрес отправителя"
          outlined
          dense
          required
          :rules="noEmptyFields"
          @input="
            getLocation(data.sender_address.value, 'sender');
            showSenderAddresses = true;
          "
        />
        <v-list
          v-if="showSenderAddresses"
          ref="sender-addreses address-list"
          class="form__sender-list address-list"
          outlined
          tile
        >
          <v-subheader>Адреса</v-subheader>
          <p v-if="data.sender_address.value.trim().length < 5">
            Введите не менее 5 символов
          </p>
          <v-progress-circular
            v-if="
              !senderAddresses.length &&
              data.sender_address.value.trim().length >= 5
            "
            indeterminate
            color="primary"
            size="40"
            width="5"
          />
          <v-list-item-group
            v-if="senderAddresses.length"
            v-model="data.sender_address.value"
            color="primary"
            class="address-list__group"
          >
            <v-list-item
              v-for="(address, i) in senderAddresses"
              :key="i"
              :value="address.value"
              class="address-list__item"
              @click="
                showSenderAddresses = false;
                data.sender_address.oktmo = address.data.oktmo;
                data.sender_address.city = address.data.city;
                data.sender_address.country = address.data.country;
              "
            >
              <v-list-item-content>
                <v-list-item-title v-text="address.value" />
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-layout>
      <v-layout class="form__receiver-address">
        <v-text-field
          v-model="data.receiver_address.value"
          label="Адрес получателя"
          outlined
          dense
          required
          :rules="noEmptyFields"
          @input="
            getLocation(data.receiver_address.value, 'receiver');
            showReceiverAddresses = true;
          "
        />
        <v-list
          v-if="showReceiverAddresses"
          ref="receiver-addreses"
          class="form__receiver-list"
          outlined
          tile
        >
          <v-subheader>Адреса</v-subheader>
          <p v-if="data.receiver_address.value.trim().length < 5">
            Введите не менее 5 символов
          </p>
          <v-progress-circular
            v-if="
              !receiverAddresses.length &&
              data.receiver_address.value.trim().length >= 5
            "
            indeterminate
            color="primary"
            size="50"
            width="5"
          />
          <v-list-item-group
            v-if="receiverAddresses.length"
            v-model="data.receiver_address.value"
            color="primary"
            class="address-list__group"
          >
            <v-list-item
              v-for="(address, i) in receiverAddresses"
              :key="i"
              class="address-list__item"
              width="100%"
              :value="address.value"
              @click="
                showReceiverAddresses = false;
                data.receiver_address.oktmo = address.data.oktmo;
                data.receiver_address.city = address.data.city;
                data.receiver_address.country = address.data.country;
              "
            >
              <v-list-item-content>
                <v-list-item-title width="100%" v-text="address.value" />
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-layout>
      <v-layout class="mb-2">
        <v-radio-group
          v-model="data.urgency"
          :rules="noUnchosenUrgency"
          class="ma-0 urgency"
        >
          <template v-slot:label>
            <div class="mb-2">Срочность</div>
          </template>
          <v-layout align-center row>
            <v-radio class="mb-0 mr-2" value="Обычная" label="Обычная" />
            <v-radio value="Экспресс" label="Экспресс" />
          </v-layout>
        </v-radio-group>
      </v-layout>
      <v-layout class="mb-5 d-flex justify-space-between">
        Содержание груза ({{ formattedItems.length }} позиций):
        <v-btn color="primary" @click="addRow"> Добавить груз </v-btn>
      </v-layout>
      <v-layout
        v-for="(item, index) in formattedItems"
        :key="index"
        class="d-flex flex-column mb-5"
      >
      <v-row class="ma-0">
        <v-select
          v-model="item.cargoType"
          outlined
          dense
          label="Тип груза"
          :items="doctypes"
          :item-text="(item)=> item.value"
          :item-value="(item)=> item"
          style="width: 80px"
          hide-details
          class="mr-1"
        />
        <v-text-field
          v-model="item.width"
          label="Ширина,см."
          outlined
          dense
          hide-details
          style="width: 40px"
          class="mr-1"
          type="number"
        />
        <v-text-field
          v-model="item.height"
          label="Высота,см."
          outlined
          dense
          hide-details
          style="width: 40px"
          class="mr-1"
          type="number"
        />
        <v-text-field
          v-model="item.length"
          label="Длина,см."
          outlined
          dense
          hide-details
          style="width: 40px"
          class="mr-1"
          type="number"
        />
        <v-text-field
          v-model="item.weight"
          label="Вес,кг."
          outlined
          dense
          hide-details
          style="width: 40px"
          class="mr-1"
          type="number"
        />
        <v-text-field
          v-model="item.description"
          label="Описание груза"
          outlined
          dense
          hide-details
          class="mr-1"
        />
        <v-icon color="red" @click="deleteRow(index)"> mdi-delete </v-icon>
        </v-row>
        <v-row class="d-flex flex-column ma-0 mt-3">
              <div class="">Дополнительные услуги:</div>
              <v-layout class="d-flex">
                <v-checkbox
                  v-for="(value, name) in item.services"
                  :key="value.caption"
                  v-model="item.services[name].value"
                  class="ma-0 mr-2"
                  :label="value.caption"
                ></v-checkbox>
              </v-layout>
            </v-row>
      </v-layout>
      <v-layout class="d-flex align-end">
        <strong v-if="sum" class="sum">Общая сумма составит: {{ sum }}</strong>
        <v-card-actions class="ml-auto d-flex justify-end">
          <v-layout class="d-flex flex-column align-end">
            <div class="mb-2">
              <v-btn class="mr-2 calculator__btn" color="primary" type="submit">
                Расчитать стоимость
              </v-btn>
              <v-btn class="calculator__btn" @click="clearData">
                Сбросить
              </v-btn>
            </div>
            <v-btn v-if="currentRouteName !== 'ExternalCalculator'" class="calculator__btn calculator__btn--nav" @click="fillOrder"
              >
               Заполнить накладную</v-btn
              >
          </v-layout>
        </v-card-actions>
      </v-layout>
    </v-form>
    <v-dialog v-model="noItems" width="30%" class="no-cargo">
      <v-card class="no-cargo__content">
        <v-btn class="no-cargo__close" @click="noItems = false">
          <v-icon> mdi-close </v-icon>
        </v-btn>
        <v-card-title class="no-cargo__title">
          Необходимо добавить хотя бы один груз!
        </v-card-title>
        <v-btn color="primary" class="no-cargo__ok" @click="noItems = false">
          ОК
        </v-btn>
      </v-card>
    </v-dialog>
  </section>
</template>
<script>
import daData from '@/api/daData';
import API from '@/api/api';
import auth from '@/api/auth';
import _ from 'lodash';

export default {
  data() {
    return {
      isValid: true,
      senderAddresses: [],
      receiverAddresses: [],
      showSenderAddresses: false,
      showReceiverAddresses: false,
      noItems: false,
      timeout: '',
      sum: 0,
      loading: false,
      data: {
        receiver_address: {
          value: '',
          oktmo: '',
          city: '',
          country: '',
        },
        sender_address: {
          value: '',
          oktmo: '',
          city: '',
          country: '',
        },
        urgency: 'Обычная',
        items: [],
        itemsCount: 0,

      },
      doctypes: [{id:'Документы', value: 'Документы'}, {id:'Прочее', value: 'Прочее'}],
      noEmptyFields: [(v) => !!v || 'Поле обязательно для заполнения'],
      noUnchosenUrgency: [(v) => !!v || 'Срочность обязательна для выбора'],
      currentRouteName: this.$route.name,
    };
  },
  computed: {
    formattedItems() {
      return this.data.items.length
        ? this.data.items.map((item) => {
            if (String(item.width).startsWith('0')) {
              item.width = Number(item.width);
            }
            if (String(item.height).startsWith('0')) {
              item.height = Number(item.height);
            }
            if (String(item.weight).startsWith('0')) {
              item.weight = Number(item.weight);
            }
            if (String(item.length).startsWith('0')) {
              item.length = Number(item.length);
            }
            return item;
          })
        : [];
    },
  },
  created() {
    window.addEventListener('click', (e) => {
      if (!e.target.closest('.form__sender-list')) {
        this.showSenderAddresses = false;
      }
      if (!e.target.closest('.form__receiver-list')) {
        this.showReceiverAddresses = false;
      }
    });
  },
  methods: {
    fillOrder() {
      this.$refs.form.validate();
      if(!this.isValid) {
        return;
      }
      this.$router.push({
        name: 'Orders',
        params: {
          order: this.data,
          openOrderModal: true,
        },
      });
    },
    async getLocation(address, type) {
      clearTimeout(this.timeout);
      if (!address) {
        return;
      }
      if (type === 'sender') {
        this.senderAddresses = [];
      } else {
        this.receiverAddresses = [];
      }
      if (address.length < 5) {
        return;
      }
      this.timeout = setTimeout(async () => {
        try {
          const { data } = await daData.getData(address, daData.baseURLAddress);
          type === 'sender'
            ? (this.senderAddresses = data.suggestions)
            : (this.receiverAddresses = data.suggestions);
        } catch (error) {
          this.showAlert(error);
        }
      }, 800);
    },
    addRow() {
      this.data.items.push({
        cargoType: {id:'Прочее', value: 'Прочее'},
        length: 0,
        width: 0,
        height: 0,
        weight: 0,
        description: '',
        services: {
          box: {
            caption: 'Коробка',
            value: false,
          },
          bubbleWrap: {
            caption: 'Воздушно-пузырьковая пленка',
            value: false,
          },
          stretchFilm: {
            caption: 'Стретч',
            value: false,
          },
          lathing: {
            caption: 'Обрешетка',
            value: false,
          },
        },
      });
      ++this.data.itemsCount;
    },
    deleteRow(index) {
      this.data.items.splice(index, 1);
      --this.data.itemsCount;
    },
    copyRow(index) {
      this.data.items.push(_.cloneDeep(this.data.items[index]));
      ++this.data.itemsCount;
    },
    clearData() {
      this.$refs.form.reset();
      this.data.receiver_address = {
          value: '',
          oktmo: '',
          city: '',
          country: '',
      };
      this.data.sender_address = {
          value: '',
          oktmo: '',
          city: '',
          country: '',
      };
      this.data.urgency = 'Обычная';
      this.data.items = [];
      this.data.itemsCount = 0;
      this.sum = 0;
    },
    async submit() {
      this.$refs.form.validate();
      if (!this.data.items.length) {
        this.isValid = false;
        this.noItems = true;
      }
      if (!this.isValid) {
        return;
      }
      this.loading = true;
      try {
        if (this.$route.name === 'ExternalCalculator' && !auth.authorized) {
          await auth.login('ServiceFront', 't3s0n3r0');
        }

        const response = await API.post('/invoices/', this.data);
        this.sum = response.summa;
      } catch (error) {
        this.showAlert(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.calculator {
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__nav {
    text-decoration: none;
  }
  &__btn {
    &--nav {
      text-decoration: none !important;
    }
  }
}

.urgency {
  .v-input__slot {
    margin-bottom: 14px !important;
  }
}

.form {
  width: 60%;

  &__sender {
    &-address {
      position: relative;
    }

    &-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      top: 40px;
      left: 0;
      z-index: 100;
      right: 0;
    }
  }

  &__receiver {
    &-address {
      position: relative;
    }

    &-list {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 40px;
      left: 0;
      right: 0;
      z-index: 100;
    }
  }
}

.address {
  &-list {
    &__group {
      width: 100%;
    }

    &__item {
      width: 100%;
    }
  }
}

.sum {
  font-size: 22px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.8);
}

.no-cargo {
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    position: relative;
  }

  &__close.theme--light.v-btn.v-btn--has-bg {
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 100%;
    padding: 2px;
    height: auto;
    min-width: auto;
  }

  &__title {
    margin-bottom: 10px;
    font-size: 22px !important;

    font-weight: 600;
  }
}
</style>
